class Developer { 
    constructor(name, job, email, experience, skills, realisations, links) { 
      this.name = name; 
      this.job = job; 
      this.email = email; 
      this.experience = experience; 
      this.skills = skills; 
      this.realisations = realisations; 
      this.links = links; 
    } 
    
    greeting() {
        console.log(`Hello ! I'm ${this.name}.`); 
    }
}

export default Developer;