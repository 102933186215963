import './App.css';
import Developer from './class/Developer';
import CodeLine from './components/CodeLine';
import CodeText from './components/CodeText';
import Navbar from './components/Navbar';

function App() {
  const me = new Developer( 
    "Florian Delacre", 
    "Front-End Developer", 
    "flo.del27@outlook.com", 
    [ 
      {"2018-2019": "Software developer's degree at AFPA"}, 
      {"2019": "Web developer internship at Tech'in Sport"}, 
      {"2020-2021": "Web developer at EDICAD"} 
    ], 
    ["HTML","CSS","Javascript","PHP","React","C#","SQL"], 
    [ 
      "Léa diététicienne", 
      "Boutique huîtres Léger Frères", 
      "Gemmatch (Android Game)", 
      "Chaotic Chess (Web browser Game)", 
      "Trista Kingdom (Web browser RPG Game)" 
    ], 
    [ 
      {"github": "github.com/f-delacre"}, 
    ] 
  );
    
  me.greeting();

  return (
    <>
      <Navbar />
      <pre className="code-editor">
          <code className="code-text">
            {/* class */}
            <CodeLine>
              <CodeText color="red" text="class " />
              <CodeText color="purple" text="Developer" />
              <CodeText text=" {" />
            </CodeLine>

            {/* Constructor */}
            <CodeLine>
              &nbsp;&nbsp;
              <CodeText color="red" text="constructor" />(
              <CodeText color="orange" text="name" />,
              <CodeText color="orange" text=" job" />,
              <CodeText color="orange" text=" email" />,
              <CodeText color="orange" text=" experience" />,
              <CodeText color="orange" text=" skills" />,
              <CodeText color="orange" text=" realisations" />,
              <CodeText color="orange" text=" links" />)
              <CodeText text=" {" />
            </CodeLine>

            {/* Properties init */}
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText color="blue" text="this" />.name
              <CodeText color="red" text=" = " />
              <CodeText color="orange" text="name" />;
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText color="blue" text="this" />.job
              <CodeText color="red" text=" = " />
              <CodeText color="orange" text="job" />;
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText color="blue" text="this" />.email
              <CodeText color="red" text=" = " />
              <CodeText color="orange" text="email" />;
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText color="blue" text="this" />.experience
              <CodeText color="red" text=" = " />
              <CodeText color="orange" text="experience" />;
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText color="blue" text="this" />.skills
              <CodeText color="red" text=" = " />
              <CodeText color="orange" text="skills" />;
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText color="blue" text="this" />.realisations
              <CodeText color="red" text=" = " />
              <CodeText color="orange" text="realisations" />;
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText color="blue" text="this" />.links
              <CodeText color="red" text=" = " />
              <CodeText color="orange" text="links" />;
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;
              <CodeText text="}" />
            </CodeLine>
          
            {/* Function init */}
            <CodeLine>
              &nbsp;
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;
              <CodeText color="purple" text="greeting" />
              <CodeText text="() {" />
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText color="blue" text="console" />.
              <CodeText color="purple" text="log" />(
              <CodeText color="value" text="`Hello ! I'm ${" />
              <CodeText color="blue" text="this" />
              <CodeText color="value" text="." />name
              <CodeText color="value" text="}.`" />);
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;
              <CodeText text="}" />
            </CodeLine>
            <CodeLine>
              <CodeText text="}" />
            </CodeLine>
          
            {/* Delaration of "Me" */}
            <CodeLine>
              &nbsp;
            </CodeLine>
            <CodeLine>
              <CodeText color="red" text="const " />
              <CodeText color="blue" text="me" />
              <CodeText color="red" text=" = new " />
              <CodeText color="purple" text="Developer" />(
            </CodeLine>

            {/* Name */}
            <CodeLine>
              &nbsp;&nbsp;
              <CodeText color="value" text='"Florian Delacre"' />,
            </CodeLine>

            {/* Job */}
            <CodeLine>
              &nbsp;&nbsp;
              <CodeText color="value" text='"Front-End Developer"' />,
            </CodeLine>

            {/* Mail */}
            <CodeLine>
              &nbsp;&nbsp;
              <CodeText color="value" text='"' />
              <CodeText type="a" link="mailto:flo.del27@outlook.com" color="value" text="flo.del27@outlook.com" />
              <CodeText color="value" text='"' />,
            </CodeLine>

            {/* Experiences */}
            <CodeLine>
              &nbsp;&nbsp;[
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText text="{" />
              <CodeText color="blue" text='"2018-2019"' />:
              <CodeText color="value" text=' "Software developer' />
              <CodeText color="value" text="'s degree at AFPA" />
              <CodeText color="value" text='"' />
              <CodeText text='}' />,
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText text="{" />
              <CodeText color="blue" text='"2019"' />:
              <CodeText color="value" text=' "Web developer internship at Tech' />
              <CodeText color="value" text="'in Sport" />
              <CodeText color="value" text='"' />
              <CodeText text='}' />,
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText text="{" />
              <CodeText color="blue" text='"2020-2021"' />:
              <CodeText color="value" text=' "Web developer at EDICAD"' />
              <CodeText text='}' />
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;],
            </CodeLine>

            {/* Skills */}
            <CodeLine>
              &nbsp;&nbsp;[
              <CodeText color="value" text='"HTML"' />,
              <CodeText color="value" text='"CSS"' />,
              <CodeText color="value" text='"Javascript"' />,
              <CodeText color="value" text='"PHP"' />,
              <CodeText color="value" text='"React"' />,
              <CodeText color="value" text='"C#"' />,
              <CodeText color="value" text='"SQL"' />],
            </CodeLine>

            {/* Realisations */}
            <CodeLine>
              &nbsp;&nbsp;[
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText color="value" text='"' />
              <CodeText type="a" link="https://leadieteticienne-en-ligne.fr/" color="value" text="Léa diététicienne" />
              <CodeText color="value" text='"' />,
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText color="value" text='"' />
              <CodeText type="a" link="https://www.boutique-huitres-leger-freres.fr/" color="value" text="Boutique huîtres Léger Frères" />
              <CodeText color="value" text='"' />,
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText color="value" text='"' />
              <CodeText type="a" link="https://play.google.com/store/apps/details?id=com.FlorianDelacre.Gemmatch" color="value" text="Gemmatch (Android Game)" />
              <CodeText color="value" text='"' />,
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText color="value" text='"' />
              <CodeText type="a" link="https://www.chaotic-chess.great-site.net/" color="value" text="Chaotic Chess (Web browser Game)" />
              <CodeText color="value" text='"' />,
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText color="value" text='"' />
              <CodeText type="a" link="http://trista-kingdom.42web.io/" color="value" text="Trista Kingdom (Web browser RPG Game)" />
              <CodeText color="value" text='"' />
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;],
            </CodeLine>

            {/* Links */}
            <CodeLine>
              &nbsp;&nbsp;[
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CodeText text="{" />
              <CodeText color="blue" text='"github"' />:
              <CodeText color="value" text=' "' />
              <CodeText type="a" link="https://github.com/f-delacre/" color="value" text="github.com/f-delacre" />
              <CodeText color="value" text='"' />
              <CodeText text='}' />,
            </CodeLine>
            <CodeLine>
              &nbsp;&nbsp;]
            </CodeLine>
            <CodeLine>
              );
            </CodeLine>
            
            {/* Console Log */}
            <CodeLine>
              &nbsp;
            </CodeLine>  
            <CodeLine>
              <CodeText color="blue" text="me" />.
              <CodeText color="purple" text="greeting" />();
            </CodeLine>

            {/* Comment */}
            <CodeLine>
              &nbsp;
            </CodeLine>  
            <CodeLine>
              <CodeText color="comment" text="// Created by Florian Delacre - Built with React" />
            </CodeLine>
          </code>
      </pre>
    </>
  );
}

export default App;