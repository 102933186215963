import React from "react"

const CodeLine = (props) => {
    return (
        <React.Fragment>
            <div className="code-line">
                {props.children}
            </div>
            <br />
        </React.Fragment>
     );
}
 
export default CodeLine;