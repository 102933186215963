import React from "react"
import "./navbar.css";
import Icon from "../assets/img/favicon.png";

const Navbar = () => {
    return ( 
        <div className="navbar">
            <img src={Icon} alt="icon" className="icon" />
            <div className="nav-element">File</div>
            <div className="nav-element">Edit</div>
            <div className="nav-element">Selection</div>
            <div className="nav-element">View</div>
            <div className="nav-element">Go</div>
            <div className="nav-element">Run</div>
            <div className="nav-element">Terminal</div>
            <div className="nav-element">Help</div>
        </div>
     );
}
 
export default Navbar;