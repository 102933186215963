import React from "react";

const CodeText = (props) => {
    const color = props.color ? "code-" + props.color : "code-normal";
    const Type = props.type ? props.type : "span";
    const link = props.link ? props.link : null;

    return (
        <React.Fragment>
            {link && <Type href={link} target="_blank" className={color}>{props.text}</Type>}
            {!link && <Type className={color}>{props.text}</Type>}
        </React.Fragment>
     );
}
 
export default CodeText;